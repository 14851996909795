import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "tableContainer",
    "mapCompanyModal",
    "newCompanyModal",
    "editCompanyModal",
    "rejectCompanyModal",
    "rejectCompanyName",
    "mapCompanyModalForm",
    "newCompanyModalForm",
    "editCompanyModalForm",
    "portfolioCompanySelect",
    "newCompanyLegalName",
    "newCompanyDisplayName",
    "editCompanyLegalName",
    "editCompanyDisplayName",
  ]

  initialize() {
    this.currentSort = this.element.dataset.sort || "company"
    this.currentSortDirection = this.element.dataset.sortDir || "asc"
    this.selectedCompany = null
  }

  initialize() {
    super.initialize()
  }

  onMapCompany(e) {
    this.selectedCompany = e.currentTarget.dataset.company
  }

  onMapConfirm(e) {
    e.preventDefault()

    const portfolioCompanyId = this.portfolioCompanySelectTarget.value
    if (!portfolioCompanyId) {
      return
    }

    const url = `/manage/snapshots/${this.element.dataset.snapshotId}/map_company`

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        company_name: this.selectedCompany,
        portfolio_company_id: portfolioCompanyId,
      }),
    })
      .then((response) => {
        if (response.ok) {
          // Reset the select dropdown
          this.portfolioCompanySelectTarget.value = ""

          // Re-fetch unmapped companies
          fetch(
            `/manage/snapshots/${this.element.dataset.snapshotId}/unmapped_companies`,
          )
            .then((response) => response.json())
            .then((companies) => {
              // Clear existing options
              while (this.portfolioCompanySelectTarget.firstChild) {
                this.portfolioCompanySelectTarget.removeChild(
                  this.portfolioCompanySelectTarget.firstChild,
                )
              }

              // Add blank option
              const blankOption = document.createElement("option")
              blankOption.value = ""
              this.portfolioCompanySelectTarget.appendChild(blankOption)

              // Add new options
              companies.forEach((company) => {
                const option = document.createElement("option")
                option.value = company.id
                option.textContent = company.legal_name
                this.portfolioCompanySelectTarget.appendChild(option)
              })
            })

          $(this.mapCompanyModalTarget).modal("hide")
          // Refresh the table to show updated mappings
          this.updateTable()
        } else {
          console.error("Failed to map company")
        }
      })
      .catch((error) => {
        console.error("Error mapping company:", error)
      })
  }

  onEdit(e) {
    this.selectedCompany = e.currentTarget.dataset.company

    // Fetch current company details
    fetch(
      `/manage/snapshots/${this.element.dataset.snapshotId}/company_details?company_name=${this.selectedCompany}`,
    )
      .then((response) => response.json())
      .then((data) => {
        this.editCompanyLegalNameTarget.value = data.legal_name
        this.editCompanyDisplayNameTarget.value = data.display_name || ""
        $(this.editCompanyModalTarget).modal("show")
      })
      .catch((error) => {
        console.error("Error fetching company details:", error)
      })
  }

  onEditConfirm(e) {
    e.preventDefault()

    const legalName = this.editCompanyLegalNameTarget.value
    if (!legalName) {
      return
    }

    const url = `/manage/snapshots/${this.element.dataset.snapshotId}/update_company`

    fetch(url, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        company_name: this.selectedCompany,
        legal_name: legalName,
        display_name: this.editCompanyDisplayNameTarget.value,
      }),
    })
      .then((response) => {
        if (response.ok) {
          $(this.editCompanyModalTarget).modal("hide")
          this.updateTable()
        } else {
          console.error("Failed to update company")
        }
      })
      .catch((error) => {
        console.error("Error updating company:", error)
      })
  }

  onCreateNew(e) {
    this.selectedCompany = e.currentTarget.dataset.companyName
    // Prepopulate legal name with company name
    this.newCompanyLegalNameTarget.value = this.selectedCompany
    this.newCompanyDisplayNameTarget.value = ""
    // Watch for changes to enable/disable create button
    this.newCompanyLegalNameTarget.addEventListener("input", () => {
      const createButton =
        this.newCompanyModalTarget.querySelector(".btn-default")
      createButton.disabled = !this.newCompanyLegalNameTarget.value
    })
  }

  onNewConfirm(e) {
    e.preventDefault()

    const legalName = this.newCompanyLegalNameTarget.value
    if (!legalName) {
      return
    }

    const url = `/manage/snapshots/${this.element.dataset.snapshotId}/create_company`

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        company_name: this.selectedCompany,
        legal_name: legalName,
        display_name: this.newCompanyDisplayNameTarget.value,
      }),
    })
      .then((response) => {
        if (response.ok) {
          $(this.newCompanyModalTarget).modal("hide")
          this.updateTable()
        } else {
          console.error("Failed to create company")
        }
      })
      .catch((error) => {
        console.error("Error creating company:", error)
      })
  }

  onReject(e) {
    this.selectedCompany = e.currentTarget.dataset.company
    this.rejectCompanyNameTarget.textContent = this.selectedCompany
    $(this.rejectCompanyModalTarget).modal("show")
  }

  onRejectConfirm(e) {
    e.preventDefault()

    const url = `/manage/snapshots/${this.element.dataset.snapshotId}/reject_company`

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
      body: JSON.stringify({
        company_name: this.selectedCompany,
      }),
    })
      .then((response) => {
        if (response.ok) {
          $(this.rejectCompanyModalTarget).modal("hide")
          this.updateTable()
        } else {
          console.error("Failed to reject company")
        }
      })
      .catch((error) => {
        console.error("Error rejecting company:", error)
      })
  }

  onContinue(e) {
    e.preventDefault()

    const url = `/manage/snapshots/${this.element.dataset.snapshotId}/map_companies`

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')
          .content,
      },
    })
      .then((response) => {
        if (response.ok) {
          $(this.mapCompanyModalTarget).modal("hide")
          window.location.reload()
        } else {
          console.error("Failed to map companies")
        }
      })
      .catch((error) => {
        console.error("Error mapping companies:", error)
      })
  }

  onSortTable(e) {
    const sort = e.currentTarget.dataset.sort

    if (this.currentSort == sort) {
      this.currentSortDirection =
        this.currentSortDirection == "asc" ? "desc" : "asc"
    } else {
      this.currentSortDirection = "asc"
      this.currentSort = sort
    }

    this.updateTable()
  }

  updateTable() {
    const data = {
      sort: this.currentSort,
      dir: this.currentSortDirection,
    }

    $.get(window.location.href, data, (response) => {
      if (this.hasTableContainerTarget) {
        this.tableContainerTarget.innerHTML = response
        this.updateContinueButton()
      }
    })
  }

  updateContinueButton() {
    const rows = this.tableContainerTarget.querySelectorAll("tbody tr")
    const allMapped = Array.from(rows).every((row) => {
      const actionsCell = row.querySelector("td:last-child")
      return (
        actionsCell &&
        (actionsCell.textContent.includes("Mapped") ||
          actionsCell.textContent.includes("Created") ||
          actionsCell.textContent.includes("Rejected"))
      )
    })

    const continueButton = this.element.querySelector(
      'button[data-action="click->snapshot-portco-review#onContinue"]',
    )
    if (continueButton) {
      continueButton.disabled = !allMapped
    }
  }
}
